import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks Querys & Context
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import { useSiteState } from '../lib/navContext';

import Layout from '../components/layout';

import ModuleZone from '~moduleZone';
import { PageMeta } from '../components/pageMeta';

const IndexPage = ({ data: { page } }) => {
  const { homeHeader, setHomeHeader } = useSiteState();
  const { pageModules, config, seo } = page || {};
  const { reverseHeader, bgColor } = config;

  useEffect(() => {
    if (homeHeader === false) {
      setHomeHeader(true);
    }
    if (bgColor.title === 'White') {
      document.documentElement.style.setProperty(`--docBg`, `#EAE9E7`);
      document.documentElement.style.setProperty(`--docBgAlt`, `#fff`);
      document.documentElement.style.setProperty(`--docText`, `#000`);
      document.documentElement.style.setProperty(`--docNavActive`, `#A7A9AB`);
    } else if (bgColor.title === 'Green') {
      document.documentElement.style.setProperty(`--docBg`, `${bgColor.value}`);
      document.documentElement.style.setProperty(`--docBgAlt`, `#E0F4E3`);
      document.documentElement.style.setProperty(`--docText`, `#fff`);
      document.documentElement.style.setProperty(`--docNavActive`, `#fff`);
    } else {
      document.documentElement.style.setProperty(`--docBg`, `${bgColor.value}`);
      document.documentElement.style.setProperty(`--docBgAlt`, `#222`);
      document.documentElement.style.setProperty(`--docText`, `#fff`);
      document.documentElement.style.setProperty(`--docNavActive`, `#AFE5BA`);
    }
  }, [reverseHeader, bgColor, setHomeHeader, homeHeader]);

  return (
    <Layout>
      <PageMeta {...seo} />
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      config {
        ...PageSettings
      }
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
